.change-password-form {
    width: 800px;
}

.password-input {
    padding: 10px;
}

.change-password-submit-button {
    margin-top: 30px;
    margin-left: 10px;
}