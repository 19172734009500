.nodeEditBox {
  position: fixed;
  right: -575px;
  top: 62px;
  background-color: white;
  /* height: 100vh; */
  overflow: scroll;
  bottom: 0px;
  width: 550px;
  // padding: 25px;
  /* margin-top: 83px; */
  z-index: 100;
  transition: right 0.3s linear;
  box-shadow: 0px 0px 10px #cbcbcb;
}

.nodeEditTitle {
  background-color: rgb(110, 105, 157);
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.copyClicked {
  background-color: #d0c8de;
  animation-name: fadeBackground;
  animation-duration: 0.75s;
}

@keyframes fadeBackground {
  0%   {background-color: #d0c8de;}
  10%  {background-color: white;}
  // 50%  {background-color: blue;}
  100% {background-color: #d0c8de;}
}

