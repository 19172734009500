.fi-container {
    text-align: left;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.fi-content-container {
    width: 100%;
    display: flex;
    margin: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.fi-content-image-container {
    width: 200px;
    display: inline-block;
}

.fi-content-image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.fi-content {
    width: 85%;
    display: inline-block;
    padding: 20px;
}

.fi-play{
    position: relative;
    color: rgba(255,255,255,0.75);
    top: -69%;
    text-align: center;
    font-weight: 700;
}

.fi-play-icon {
    font-size: 32px;
}