.module-image {
    margin: 15px;
    cursor: pointer;
    opacity: 0.9;
    z-index: -1;
}:hover {
    opacity: 1;
}

.module-image.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}