.scrollHolder {
  height: calc(100vh - 10em);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.Buttons {
  position: absolute;
  right: 0;
  z-index: 1;
  padding: 25px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.testBtn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  padding: 10px 20px;
  background: green;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px #e8e8e8;
  cursor: pointer;
}
