@import url("https://unpkg.com/treeflex@2.0.1/dist/css/treeflex.css");

.titleNode {
  background-color: #6e699d;
  color: white;
}

.tf-tree li li:only-child > .tf-nc:before,
.tf-tree li li:only-child > .tf-node-content:before {
  display: none;
}

.tf-tree .tf-nc:after,
.tf-tree .tf-node-content:after {
  display: none;
}

.tf-tree .tf-nc:after,
.tf-tree .tf-nc:before,
.tf-tree .tf-node-content:after,
.tf-tree .tf-node-content:before {
  display: none;
}

.tf-tree li li:first-child:before {
  display: none;
}

.tf-tree li li:before {
  display: none;
}

.mainNode {
  background: white;
  box-shadow: 0px 0px 10px #c9c9c9;
  border-radius: 5px;
  // transition: all 0.3s ease-in-out;
}

.fullTreeHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alignTreeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Tree_MatchItem {
  color: white;
  width: fit-content;
  border-radius: 20px;
  padding: 2px 8px;
}

.toggleView {
  margin-top: -31px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}