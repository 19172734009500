.splitNode_leftPane {
  flex: 1;
}

.splitNode_rightPane {
  flex: 1;
}

.addColumnButton {
  color: white;
  margin: 8px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.selectorGroup {
  display: flex;
  align-items: center;
}

.selectorName {
  font-weight: bold;
  cursor: pointer;
  font-size: 22px;
}

.selectorAdd {
  color: white;
  padding: 5px;
  height: 23px;
  width: 23px;
  display: flex;
  border-radius: 70px;
  justify-content: center;
  margin-left: 5px;
  align-items: center;
  cursor: pointer;
}

.comparisonGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.questionHolder {
  border-left: 3px solid purple;
  padding-left: 15px;
  margin-left: 5px;
  margin-top: 10px;
}
