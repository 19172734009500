.home-content {
  background-image: url('../../Assets/Wave Graphic.svg'); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
}

.home-news {
  display: flex;
}

.home-tag {
  margin-top: 9px;
  margin-bottom: -9px;
  text-align: center;
  font-size:12px;
  font-weight:700;
  width: fit-content;
  padding: 1px 10px;
  border-radius: 20px;
  color: white;
  background-color: blueviolet;
}

.home-news-content {
  margin-left: 20px;
  width: 68%;
}

.home-news-card {
  display: flex;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.home-news-actions {
  display: flex;
  justify-content: center;
  flex-direction:column;
}

.home-quicklink {
  text-align: center;
  float: left;
  border-style:solid;
  border-width:1px;
  border-radius: 7px;
  border-width: 2px;
  border-color: rgba(23, 4, 75, 0.75);
  color:rgba(23, 4, 75, 0.75);
  /* width: 400px; */
  min-width: 200px;
  flex-grow: 1;
  height: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.home-quicklink-image  {
  margin: 20px;
  max-width: 60%;
}

.home-news-image-container {
  background-image:url("../../Assets/Gradient Wave.svg");
  background-color: #756ca9;
  width: 160px;
  /* height: 150px; */
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
}


.home-quicklink-image-container {
  height:78%;
  border-radius: 7px;
  background: white;
  text-align: center;
}

.home-quicklink-icon {
  float: right;
  margin-right: 20px;
}

.home-quicklink-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  height: auto;
  width: 60%;
  max-width: 70%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}