.Card_TextContainer {
  text-transform: uppercase;
  font-size: 14px;
  color: white;
  letter-spacing: 2px;
}

.Card_Main {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Card_Image {
  display: flex;
  justify-content: center;
  align-items: center;
}
