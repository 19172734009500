
.pm-top-section {
    display: inline-flex;
    width: 100%;
}

.pm-bottom-section {
    box-shadow: 1px 1px 6px #aaaaaa;
    padding: 20px;
    border-radius: 10px;
    margin-top: 12px;
}
  
.pm-patient-info, .pm-drug-interactions {
    box-shadow: 1px 1px 12px #7c7a7a;
    padding: 20px;
    border-radius: 10px;
}

.pm-patient-info {
    width: 60%;   
    margin-right: 12px;
}

.pm-patient-info-labels, .pm-patient-info-content {
    display: inline-flex;   
    width: 80%;
    justify-content: space-around;
    
    margin-top: 15px;
}

.pm-patient-info-labels {
    background-color: rgba(8, 243, 145, 0.144);
    border-radius: 20px;
    font-weight: 700;
}

.pm-drug-interactions {
    width: 40%;
    display: inline-flex;
    justify-content: space-between;
}

.pm-di-red {
    background-image: -webkit-linear-gradient(left, #b9203b, #b9203b 1.2%, transparent 0%, transparent 100%);
    padding-left: 32px;
}

.pm-di-alert {
    color: #b9203b;
}

.pm-di-transparent {
    opacity: 50%;
}

.pm-di-text {
    font-style: italic;
}

.pm-table-header {
    background-color: rgb(241 241 241);
    font-weight: bold;
}

.pm-header-divider {
    border-left: 1px solid #38546d;
    background: #16222c;
    width: 1px;
    height: 80px;
    position: absolute;
    right: 250px;
    top: 10px;
}

.pm-drug-interactions-list-container, .pm-check-medications-list-container {
    margin-left: 40px;
    margin-right: 40px;
    height: 100%;
    overflow:auto;
    padding-right: 12px;
    max-width: 300px;
}

.pm-drug-interaction-container {
    width: 100%;
    /* border-color: #b9203b; */
    border-width: thin;
    border-style: solid;
    border-radius: 3px;
    padding: 20px;
    background-color: rgb(241 241 241);
    margin-bottom: 20px;
    cursor: pointer;
}

.severity-container-major {
    border-color: #b9203b;
}

.severity-container-moderate {
    border-color: rgb(218, 129, 13);
}

.severity-container-minor {
    border-color: green;
}

.severity-text-major {
    color: #b9203b;
}

.severity-text-moderate {
    color: rgb(218, 129, 13);
}

.severity-text-minor {
    color: green;
}

.pm-medication-search-item {
    width: 100%;
    color: #1d0b6d;
    border-radius: 2px;
    padding: 20px;
    background-color: rgb(241 241 241);
    margin-bottom: 20px;
    
}
.pm-medication-search-item:hover {
    cursor: pointer;
    border-width: 1px;
    background-color: rgb(235, 233, 233);
}

.pm-medication-search-item-selected {
    border-style: solid;
    border-width: 1px;
    background-color: rgb(235, 233, 233);
}

.pm-medication-search-item-flash {
    border-color: red;
    border-width: 2px;
}

.pm-drug-interactions-list-header {
    color: #b9203b;
}

.pm-drug-interaction-item-header {
    font-weight: 600;
    margin-bottom: 10px;
}

.pm-drug-interactions-actions-container, .pm-check-medications-actions-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.pm-check-medication-section {
    margin-top:36px;
}

.pm-tooltip {
    background-color: antiquewhite;
    color: black;
    padding: 10px;
}
