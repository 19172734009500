.tempButton {
  background-color: gray;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 21px;
  margin-right: 10px;
  cursor: pointer;
}

.pm-top-section {
  display: inline-flex;
}

.pm-patient-info, .pm-drug-interactions {
  box-shadow: 1px 1px 6px #aaaaaa;
  padding: 10px;
}

