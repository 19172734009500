.content {
    padding: 20px;
}

.profile-picture {
    border-radius: 50%;
    height: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-color: #d3d3d3;
    margin: 50px;
    padding: 5px;
}

.progress {
    margin-left: 20px;
}

.fields {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.field-category {
    padding: 30px;
    margin: 10px;
    width: 300px;
}

.message {
    color:grey;
    font-size: 12px;
    float: right;
}

.text-input {
    width: 100%;
    padding: 10px;
}