.mainContainer {
    display: flex;
    justify-content: space-between;
    height: 100vh
}

.containerContent {
    flex: 1
}

.containerImage {
    background-size: cover;
    background-position: center;
}

.pageTitle {
    color: navy;
    font-size: 28px;
    text-align: center;
    padding-bottom: 40px;
}

.containerBox {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotPasswordButton {
    text-align: right;
    color: #558be6;
    /* text-decoration: underline; */
    cursor: pointer;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
    font-weight: 600;
}

.authErrorMessage {
    padding: 5px 10px;
    background: #ff5538;
    color: white;
    border-radius: 3px;
}

.animationHolder {
    background-color: #C9EFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    transition: opacity 0.5s;
}

.apolloHolder {
    transition: opacity 0.5s;
}


.passwordErrorMsg {
    color: red;
    margin-top: -13px;
    font-size: 14px;
}

.passwordChecklistItem {
    display: flex;
    align-content: center;
    margin-bottom: 3;
    /* transition: color linear 0.2s; */
}