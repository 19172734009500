.basicButton {
  margin-bottom: 8px;
  color: white;
  cursor: pointer;
  background: #f45454;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 0px 5px #d8d8d8;
  padding: 10px;
  transition: backgroundColor 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.alterChartBtn {
  height: 11px;
  width: 11px;
  text-align: center;
  vertical-align: middle;
  fill: #848484;
}

.iconHolder {
  display: flex;
}
