.formBuilder_bottomIcon {
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
  border-right: 1px solid gray;
  cursor: pointer;
}

.formBuilder_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
