.company-info, .payment-method {
    padding: 30px;
    margin: 20px;
    width: 50%;
}

.company-info-and-payment, .company-logos {
    display: flex;
}

.company-info-input {
    margin: 20px;
}

.company-theme-color-display {
    width: 200px;
    height: 150px;
}

.company-theme-card {
    padding: 30px;
    margin: 10px;
    cursor: pointer;
}

.company-logo {
    border-radius: 50%;
    height: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-color: #d3d3d3;
    margin: 50px;
    padding: 5px;
    height: auto;
    width: 200px;
}

.company-theme-cards {
    display: flex;
    border-radius: 2px;
}

.company-theme-and-logo {
    padding: 30px;
    margin: 20px;
    width: 100%;
    display: flex;
}


.card-logo-payment-plan-container {
    margin: 20px;
    display: flex;
}

.payment-plan, .plan-due-date {
    margin-left: 20px;
    padding-top: 5px;
    color: darkblue;
}

.plan-text, .payment-due-text {
    margin: 0px;
}

.card-logo {
    background: darkblue;
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
    width: 79px;
    color: white;
    padding: 10px;
}


.card-number {
    margin: 20px;
}

.card-expires-container {
    display: flex;
    margin: 20px;
}

.card-expires-mm, .card-expires-yy{
    margin-right: 10px;
    width: 80px;
}
